import type { NextPage } from 'next'
import Head from 'next/head'
import type { Organization, WebSite } from 'schema-dts'

import { JsonLd, ORGANIZATION_JSON } from '../components/json-ld'
import type { StaticPageData } from '../lib/static/page'
import {
    createStaticPage,
    getContenfulStaticProps,
} from '../lib/static/static-page'
import { useClickTracker } from '../lib/store/tracker/ClickTracker'
import { PAGE_OPTIONS } from '../lib/utils/page'

export const getStaticProps = getContenfulStaticProps(PAGE_OPTIONS.HOME.slug)
const Page = createStaticPage(PAGE_OPTIONS.HOME)

const Home: NextPage<StaticPageData> = (pageProps) => {
    useClickTracker({ feature: 'get-app-button-homepage' })

    return (
        <>
            <Head>
                <meta
                    key='facebook-domain-verification'
                    name='facebook-domain-verification'
                    content='xoedfnjptcbqfjuuml3pb26v58g5vp'
                />
            </Head>

            <Page {...pageProps} />

            <JsonLd<Organization> data={ORGANIZATION_JSON} />

            <JsonLd<WebSite>
                data={{
                    '@type': 'WebSite',
                    name: 'Step',
                    url: 'https://step.com/',
                }}
            />
        </>
    )
}

export default Home
